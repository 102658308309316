import { isSuppressEagerConnect } from '@telekomconsalting/react-dexguru-wallet'
import { createBrowserHistory, History } from 'history'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import { initSettings, setIsAuth } from './actions'
import { fetchNotifications } from './actions/notificationsAction'
import { useRouteChange } from './components/ChatWidget/chatHooks'
import { useConnectors } from './components/ConnectorsContext'
import Content from './components/Content/Content'
import ErrorBoundary from './components/ErrorBoundary'
import { useAuth } from './containers/AuthWrapper'
import SnackbarWrapper from './containers/SnackbarWrapper'
import TokenStaticMetadataLoader from './containers/TokenStaticMetadataLoader'
import Web3Wrapper from './containers/Web3Wrapper'
import useLazyEagerConnect from './hooks/useLazyEagerConnect'
import { State } from './reducers'
import {
  HISTORY_ROUTE_TEMPLATE,
  LIQUIDITY_ROUTE_TEMPLATE,
  MULTICHART_ROUTE,
  PRICING_ROUTE,
  ROOT_ROUTE,
  SEASON_PASS_ROUTE,
  TOKEN_PROFILE_ROUTE_TEMPLATE,
  TOKEN_ROUTE_TEMPLATE,
  TOP_CRYPTOS_NETWORK_ASSETS_TEMPLATE,
  TOP_CRYPTOS_NETWORK_TEMPLATE,
  TOP_CRYPTOS_ROUTE,
  TRADER_PROFILE_TEMPLATE,
  TRADER_PROFILE_TRADE_TEMPLATE,
} from './routes'
import { isABot } from './services/botService'
import { removeAllServiceWorkers } from './utils'

removeAllServiceWorkers()

const history: History = createBrowserHistory()

const App: FC = () => {
  const reduxDispatch = useDispatch()
  const { isAuth, setForceDisconnect } = useAuth()
  const appStateIsAuth = useSelector((state: State) => state.isAuth)
  const { walletConnectors, initWalletConnectors } = useConnectors()
  const { isLoading: settingsIsLoading, error: settingsError } = useSelector(
    (state: State) => state.settings
  )
  const isMobile = useSelector((state: State) => state.isMobile)

  const networksConfig = useSelector((state: State) => state.networksConfig.data)
  const accountsList = JSON.parse(localStorage.getItem('accounts') || '[]')
  useLazyEagerConnect(
    !accountsList || isSuppressEagerConnect(),
    walletConnectors,
    initWalletConnectors,
    networksConfig
  )

  useEffect(() => {
    reduxDispatch(setIsAuth(isAuth))
    reduxDispatch(initSettings(isAuth))
  }, [isAuth, reduxDispatch])

  useEffect(() => {
    if (process.env.REACT_APP_NOTIFICATIONS === 'true' && appStateIsAuth) {
      reduxDispatch(fetchNotifications())
    }
  }, [appStateIsAuth, reduxDispatch])

  useEffect((): void => {
    // hotfix: https://app.shortcut.com/dexguru/story/16385/b-impossible-to-add-remove-tokens-to-from-favorites
    if (settingsError?.status === 422 && appStateIsAuth === false && appStateIsAuth !== isAuth) {
      setForceDisconnect(true)
    }
  }, [appStateIsAuth, isAuth, settingsIsLoading, settingsError, setForceDisconnect])

  useRouteChange(history)

  const isBot = isABot()

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={[
            ROOT_ROUTE,
            TOKEN_ROUTE_TEMPLATE,
            LIQUIDITY_ROUTE_TEMPLATE,
            HISTORY_ROUTE_TEMPLATE,
            TOKEN_PROFILE_ROUTE_TEMPLATE,
            TRADER_PROFILE_TEMPLATE,
            TRADER_PROFILE_TRADE_TEMPLATE,
            TOP_CRYPTOS_ROUTE,
            TOP_CRYPTOS_NETWORK_TEMPLATE,
            TOP_CRYPTOS_NETWORK_TEMPLATE,
            TOP_CRYPTOS_NETWORK_ASSETS_TEMPLATE,
            MULTICHART_ROUTE,
            SEASON_PASS_ROUTE,
            PRICING_ROUTE,
          ]}>
          <ErrorBoundary isMobile={isMobile}>
            <TokenStaticMetadataLoader />
            <SnackbarWrapper>
              <Web3Wrapper>
                <Content />
              </Web3Wrapper>
            </SnackbarWrapper>
          </ErrorBoundary>
        </Route>
        {!isBot && (
          <Route path="*">
            <Redirect to={{ pathname: ROOT_ROUTE }} />
          </Route>
        )}
        {isBot && (
          <Route
            path="*"
            render={(): null => {
              // needs to be absolute to hit CF
              window.location.href = `https://${window.location.hostname}/404`
              return null
            }}
          />
        )}
      </Switch>
    </Router>
  )
}

export default App
